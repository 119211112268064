import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import 'bootstrap'
import Spinner from '@/components/Spinner.vue'
import VueGoodTablePlugin from 'vue-good-table-next'
import { createPinia } from 'pinia'
import VueSelect from 'vue-select'
import VueProgressBar from '@aacassandra/vue3-progressbar'
import './registerServiceWorker'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import VueAwesomePaginate from 'vue-awesome-paginate'
import 'vue-awesome-paginate/dist/style.css'
import 'vue-good-table-next/dist/vue-good-table-next.css'

const options = {
    color: '#f7b731',
    failedColor: '#f7b731',
    thickness: '5px',
    transition: {
        speed: '0.4s',
        opacity: '0.6s',
        termination: 300,
    },
    autoRevert: true,
    location: 'top',
    inverse: false,
}

const pinia = createPinia()
const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueGoodTablePlugin)
app.use(pinia)
app.use(VueProgressBar, options)
app.use(VueViewer)
app.use(VueAwesomePaginate)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('Spinner', Spinner) // spinner
app.component('v-select', VueSelect)
app.component('DocsExample', DocsExample)

app.mount('#app')
